import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import'./scss/Common.scss';
import Auth from './components/Auth';
import {baseURL} from './common/Setting';
import { Buffer } from "buffer";
window.Buffer = window.Buffer || Buffer;

const loading = () => (
  <div className="animated fadeIn pt-3 text-center"> Loading... </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));

// @ts-ignore
window.axios = require('axios');
// @ts-ignore
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// @ts-ignore
window.axios.defaults.withCredentials = true;
// window.baseUrl = 'https://xs591901.xsrv.jp/api/admin';
// window.baseUrl = "http://tran-it.biz/foster/laravel/public/api/admin";
// @ts-ignore
window.baseUrl = baseURL();

// バージョン管理
// 本部画面バージョン:デプロイする日付+その日にデプロイした回数


class App extends Component {
  state = {
    apiKey: '',
  };

  // @ts-ignore
  _setApiKey = (apiKey) => {
    this.setState({ apiKey: apiKey });
    window.localStorage.setItem('invoiceApiKey', apiKey);
  };

  componentWillMount = async () => {
    let apiKey = window.localStorage.getItem('invoiceApiKey');
    if (apiKey === null) apiKey = '';
    this.setState({ apiKey });
  };

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              // @ts-ignore
              exact
              path="/login"
              name="Login"
              // @ts-ignore
              render={(props) => (
                <Login
                  apiKey={this.state.apiKey}
                  setApiKey={this._setApiKey}
                  {...props}
                />
              )}
            />
            <Auth apiKey={this.state.apiKey}>
              <Route
                path="/"
                // @ts-ignore
                name="Home"
                // @ts-ignore
                render={(props) => (
                  <DefaultLayout
                    apiKey={this.state.apiKey}
                    setApiKey={this._setApiKey}
                    {...props}
                  />
                )}
              />
            </Auth>
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
